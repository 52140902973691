import Vue from 'vue';
import App from './App.vue';

// ** Primevue
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primeicons/primeicons.css';
Vue.use(PrimeVue);

// ** Password Textfield
import Password from 'primevue/password';
// eslint-disable-next-line
Vue.component('Password', Password);

// ** Dialog
import Dialog from 'primevue/dialog';
// eslint-disable-next-line
Vue.component('Dialog', Dialog);

// ** Routes
import VueRouter from 'vue-router';
import routes from './routes/';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes,
});

// ** Vee validate
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// ** Axios
import axios from 'axios';
Vue.prototype.axios = axios;

// ** Sweetalert
import swal from 'sweetalert2';
Vue.prototype.swal = swal;

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_ENVIRONTMENT === 'development';
// ** Disable console debug
if (process.env.VUE_APP_ENVIRONTMENT !== 'development') {
  // eslint-disable-next-line
  console.log = function () {};
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
