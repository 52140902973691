<template>
    <Dialog
      :visible.sync="isCashierExpired"
      header="Akun Kadaluarsa"
      :modal="true"
      :containerStyle="{ width: '35vw' }"
      :baseZIndex="30"
      @hide="hide"
      class="dialog-expired dialog-responsive"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        style="text-align: center"
      >
        Maaf anda belum bisa melakukan transaksi di POS. Silahkan melakukan
        perpanjangan akun melalui Backoffice.
      </div>

      <div class="d-flex justify-content-center align-items-center mt-3">
        <button class="btn btn-outline text-purple" @click="redirectToWhatsApp">
          Hubungi tim Qollega
        </button>
        <button class="btn btn-primary" @click="isCashierExpired = false">
          Oke, Mengerti
        </button>
      </div>
    </Dialog>
</template>
<script>

export default {
  name: 'DialogExpired',
  props: ['is_cashierExpired'],
  data: function () {
    return {
      isCashierExpired: false,
    };
  },
  watch: {
    is_cashierExpired(val) {
      this.isCashierExpired = val;
      this.$emit('hide', val)
    }
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    redirectToWhatsApp() {
      window.open('https://api.whatsapp.com/send?phone=08111599333', '_blank');
    },
  },
};
</script>

<style scoped>

</style>
