<template>
  <div v-if="!isLogin">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" id="form_login">
        <div class="auth-wrapper cotainer-fluid align-items-stretch">
          <div
            class="row align-items-center w-100 align-items-stretch"
            style="background: #f4f4f4"
          >
            <div class="d-none d-lg-flex col-lg-4 aut-bg-img">
              <div class="col-md-12 ps-2 pe-2 pt-5">
                <div class="text-center">
                  <img :src="logo" class="img-fluid" style="width: 50%" />
                </div>
                <h3 class="text-white text-center mt-4 mb-5">
                  This is a demo version
                  <span class="text-yellow-90">
                    intended for demonstration purposes only and not for commercial use.
                  </span>
                </h3>
                <div class="text-center">
                  <img
                    :src="illustration"
                    class="img-fluid"
                    style="width: 87%"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-8 flex-center">
              <div class="card">
                <div class="d-flex justify-content-end pt-4 pe-4">
                  <img
                    src="@/assets/images/demo.png"
                    alt="Demo"
                    style="width: 50px; opacity: 50%"
                  />
                </div>
                <div class="header">
                  <h3 class="mb-2 text-purple-90 f-w-600 f-24">
                    Hai, Selamat Datang
                  </h3>
                  <h4 class="text-muted mb-4 f-18">
                    Silakan masuk ke akun Anda.
                  </h4>
                  <div class="mb-2 d-grid">
                    <ValidationProvider
                      name="Email"
                      rules="email|required"
                      v-slot="{ errors }"
                    >
                      <div class="login__field">
                        <i class="login__icon feather icon-mail"></i>
                        <input
                          type="email"
                          v-model="email"
                          class="login__input"
                          placeholder="Email"
                          id="email_input"
                        />
                        <span
                          class="text-danger d-block text-start"
                          v-if="errors[0]"
                        >
                          Email wajib diisi.
                        </span>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Password"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <div class="login__field">
                        <i
                          class="password__icon icon feather icon-eye"
                          @click="showPassword()"
                          id="password_icon"
                        ></i>
                        <i class="login__icon feather icon-lock"></i>
                        <input
                          type="password"
                          class="login__input"
                          v-model="password"
                          placeholder="Password"
                          id="password_input"
                        />
                        <span
                          class="text-danger d-block text-start"
                          v-if="errors[0]"
                        >
                          Password wajib diisi.
                        </span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="d-flex mb-4">
                    <div class="ms-auto">
                      <a
                        href="/forgot_password"
                        class="forgot-password"
                        id="forgot_password_link"
                      >
                        Lupa Password?
                      </a>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center flex-column flex-sm-row gap-3 py-3"
                  >
                    <button
                      class="btn btn-primary btn-pill ps-4"
                      type="submit"
                      :disabled="isLoading"
                      id="login_button"
                    >
                      Login
                      <span
                        class="spinner-border spinner-border-sm ms-2"
                        role="status"
                        v-show="isLoading"
                      ></span>
                      <i
                        class="feather icon-arrow-right ms-1"
                        v-show="!isLoading"
                      ></i>
                    </button>
                    <div
                      class="checkbox p-0 m-0 checkbox-fill d-flex text-left"
                    >
                      <input
                        type="checkbox"
                        name="checkbox_remember"
                        id="checkbox_remember"
                      />
                      <label for="checkbox_remember" class="cr text-black">
                        Ingat Saya
                      </label>
                    </div>
                  </div>
                </div>
                <!--<div class="footer flex-sm-row justify-content-sm-between">
                  <p class="f-14 m-0 f-w-600">Belum punya akun?</p>
                  <a
                    href="register"
                    id="register_button"
                    class="btn btn-sm btn-outline-primary btn-pill text-purple f-w-600"
                  >
                    Daftar Sekarang
                  </a>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <DialogExpired
      :is_cashierExpired="isCashierExpired"
      @hide="(value) => (isCashierExpired = value)"
    />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>
<script>
import Logo from '@/assets/images/demo_white.png';
import Illustration from '@/assets/images/bg-images/auth_background.png';
import DialogExpired from './DialogExpired.vue';
import { redirect_subscription } from '@/utils/helpers.js';

export default {
  name: 'LoginPage',
  components: {
    DialogExpired,
  },
  data: function () {
    return {
      isLoading: false,
      isLogin: false,
      isCashierExpired: false,
      logo: Logo,
      illustration: Illustration,
      email: '',
      password: '',
      totalWarehouse: 0,
    };
  },
  watch: {
    totalWarehouse() {
      this.checkPage();
    },
  },
  methods: {
    redirect_subscription,
    hide() {
      this.$emit('hide', this.isCashierExpired);
    },
    showPassword() {
      var passInput = document.getElementById('password_input');
      if (passInput.type === 'password') {
        passInput.type = 'text';
      } else {
        passInput.type = 'password';
      }
    },
    redirectToWhatsApp() {
      window.open('https://api.whatsapp.com/send?phone=08111599333', '_blank');
    },
    async submit() {
      try {
        this.isLoading = true;
        const data = new FormData();
        data.append('email', this.email);
        data.append('password', this.password);

        const req = await this.axios.post(
          `${process.env.VUE_APP_API_URL}oth/masuk`,
          data
        );
        const res = req.data;

        localStorage.setItem('user_data', JSON.stringify(res));
        localStorage.setItem('token', res.token);

        let warehouseID =
          res.role_name !== 'Owner'
            ? res.warehouse_id
            : res.business?.default_warehouse;
        localStorage.setItem('warehouse_id', warehouseID);

        this.totalWarehouse =
          res.role_name !== 'Owner' ? 0 : res.list_cabang?.length;

        if (res.role_name !== 'Owner') {
          window.location.href = '/pos';
        } else {
          if (this.totalWarehouse === 1) {
            const res = await this.axios.post(
              `${process.env.VUE_APP_API_URL}beranda/login-cabang`,
              { warehouse_id: warehouseID },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            );

            this.redirect_subscription(res.data.success, res.data.selisih);
          } else {
            this.isLogin = true;
            this.$router.push({ name: 'pilih_cabang' }).catch(() => {});
          }
        }
      } catch (e) {
        console.error(e);
        this.isLoading = false;

        if (
          e.response &&
          e.response?.status === 401 &&
          e.response?.data &&
          e.response?.data.is_expired
        ) {
          this.isCashierExpired = true;
        } else {
          this.swal.fire({
            title: 'Gagal Masuk',
            text:
              e.response?.status !== 500
                ? e.response?.data.message
                : 'Mohon maaf, terjadi kesalahan pada server kami.',
            icon: 'error',
          });
        }
      }
    },
    checkPage() {
      if (
        localStorage.getItem('token') ||
        localStorage.getItem('warehouse_id')
      ) {
        if (this.totalWarehouse === 1) {
          this.isLogin = false;
          return;
        }

        this.$router.push({ name: 'pilih_cabang' }).catch(() => {});
        this.isLogin = true;
      }
    },
  },
  created() {
    this.checkPage();
  },
};
</script>

<style>
.dialog-expired .p-dialog .p-dialog-header {
  display: flex;
  justify-content: center;
}

.dialog-expired .p-dialog .p-dialog-header-icons {
  display: none;
}
</style>

<style scoped>
body {
  background: linear-gradient(90deg, #c7c5f4, #776bcc);
}

.card {
  width: 450px;
  border-radius: 20px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.11) !important;
}

.header {
  padding: 8px 48px 48px;
}

.login__field {
  padding: 20px 0px 16px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 32px;
  color: #a1a1aa;
}

.password__icon {
  position: absolute;
  right: 5px;
  top: 35px;
  transition: 0.25s ease;
}

.password__icon:hover {
  cursor: pointer;
  transform: scale(1.5);
}

.login__input {
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 600;
  width: 100%;
  transition: 0.2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover,
.login__field:hover .login__input {
  outline: none;
  border-bottom-color: #9395f5;
}

.forgot-password {
  font-weight: 400 !important;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 4px;
  color: #5d5fef;
}

.login__field:hover .login__icon {
  color: #9395f5;
}

.footer {
  background-color: #e2e2e2;
  text-align: center;
  padding: 20px 48px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

@media (max-width: 576px) {
  .btn {
    width: 100%;
  }

  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none !important;
  }

  .flex-center,
  .card {
    padding: 0;
    margin: 0;
  }

  .header,
  .footer {
    padding: 20px 16px;
    border-radius: 0;
  }
}
</style>
