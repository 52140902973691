const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/login/App.vue'),
    children: [
      {
        name: 'pilih_cabang',
        path: 'pilih_cabang',
        component: () => import('@/login_percabang/App.vue'),
      },
    ],
  },
];
export default routes;
